import axios from 'axios';
import cookie from 'react-cookies'

import {
    FETCH_MERCHANT_DETAIL_REQUEST,
    FETCH_MERCHANT_DETAIL_SUCCESS,
    FETCH_MERCHANT_DETAIL_FAILURE,
    FETCH_MERCHANT_DETAIL_SUCCESS_ERROR,
    FETCH_MEMBER_DETAIL_REQUEST,
    FETCH_MEMBER_DETAIL_SUCCESS,
    FETCH_MEMBER_DETAIL_FAILURE,
    FETCH_MEMBER_DETAIL_SUCCESS_ERROR
} from './pywLearnMoreDetailsTypes';

export const fetchMerchantDetails = (requestBody, requestHeaders) => {
    return (dispatch) => {
        dispatch(fetchMerchantDetailsRequest());
        axios.get(process.env.REACT_APP_MERCHANT_DETAILS_URL+ "?ftoken=" + cookie.load("forterToken"), requestHeaders, requestBody
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchMerchantDetailsSuccessError(resp.data));
            }
            else {
                dispatch(fetchMerchantDetailsSuccess(resp.data));
            }
        }).catch(err => {
            dispatch(fetchMerchantDetailsFailure(err.message));
        });
    }
}

export const fetchMerchantDetailsRequest = () => {
    return {
        type: FETCH_MERCHANT_DETAIL_REQUEST
    }
}

export const fetchMerchantDetailsSuccessError = apiData => {
    return {
        type: FETCH_MERCHANT_DETAIL_SUCCESS_ERROR,
        payload: apiData
    }
}

export const fetchMerchantDetailsSuccess = apiData => {
    return {
        type: FETCH_MERCHANT_DETAIL_SUCCESS,
        payload: apiData
    }
}

export const fetchMerchantDetailsFailure = error => {
    return {
        type: FETCH_MERCHANT_DETAIL_FAILURE,
        payload: error
    }
}

export const fetchMemberDetails = (requestBody,requestHeaders) =>{
    return async (dispatch) => {
        try {
            dispatch(fetchMemberDetailsRequest());
            const resp = await axios.post(process.env.REACT_APP_MEMBER_DETAILS_URL,requestBody,requestHeaders)
            if(resp.data.errors!==undefined && resp.data.errors.length!==0){
                dispatch(fetchMemberDetailsSuccessError(resp.data));
            }else{
                sessionStorage.setItem("pywlmusersessionid",resp.data.userSessionId);
                dispatch(fetchMemberDetailsSuccess(resp.data));
            }
        } catch (err) {
            dispatch(fetchMemberDetailsFailure(err.message));
        }
    }
}
export const fetchMemberDetailsRequest = () =>{
    return {
        type : FETCH_MEMBER_DETAIL_REQUEST
    }
}
export const fetchMemberDetailsSuccess = apiData =>{
    return {
        type : FETCH_MEMBER_DETAIL_SUCCESS,
        payload : apiData
    }
}
export const fetchMemberDetailsSuccessError = apiData =>{
    return {
        type : FETCH_MEMBER_DETAIL_SUCCESS_ERROR,
        payload : apiData
    }
}
export const fetchMemberDetailsFailure = error =>{
    return{
        type : FETCH_MEMBER_DETAIL_FAILURE,
        payload : error
    }
}
