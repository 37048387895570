import mastercard from "../assets/images/mastercard.png";
import cart from "../assets/images/cartIcon.png";
import info from "../assets/images/Info.svg";
import earning from "../assets/images/Earning1.png";
import lock from "../assets/images/Lock.svg";
import closewhite from "../assets/images/Close_White.svg";
import user from "../assets/images/user.svg";
import sywpoints from "../assets/images/SYW_points.svg";
import sywpointsorange from "../assets/images/SYW_points_orange.svg";
import creditcardplane from "../assets/images/creditcardplane.png";
import grace from "../assets/images/grace.png";
import close from "../assets/images/Close.svg";
import check from "../assets/images/Check.svg";
import masterCard from "../assets/images/masterCard.svg";
import visa from "../assets/images/visa.svg";
import discover from "../assets/images/discover.svg";
import illustration from "../assets/images/Illustration.png";
import loadingCard from "../assets/images/Loading_card.gif";
import closeRed from "../assets/images/close_red.png";
import rightGreen from "../assets/images/right_green.png";
import cardError from "../assets/images/cardError.png";
import NoCreditCardBlue from "../assets/images/NoCreditCard_blue.png";
import NoCreditCard from "../assets/images/NoCreditCard.svg";
import Email from "../assets/images/email.png";
import Aero from "../assets/images/Aero.png";
import reload from "../assets/images/Reload.jpg";
import Path from "../assets/images/Path.png";
import masterCardStright from "../assets/images/creditcardplane.png";
import UnifiLogo from "../assets/images/Unifi.png";
import PartyIcon from "../assets/images/Party.png";
import Raiselogo from "../assets/images/raiselogo.png";
import UserImg from "../assets/images/userImage.svg";
import Shop from "../assets/images/shop.png";
import Your from "../assets/images/your.png";
import Way from "../assets/images/way.png";
import SYWLogo from "../assets/images/SYWLogo.png";
import frameSummary from "../assets/images/FrameSummary.png";
import frameSummary1 from "../assets/images/FrameSummary1.png";
import SYWLogo_Black from "../assets/images/SYW_logo_black.svg";
import rightArrow from "../assets/images/rightArrow.svg";
import bankCardIcon from "../assets/images/bankCardIcon.svg";
import searsHomeServicesLogo from "../assets/images/searsHomeServicesLogo.svg"
import payYourWayLogo from "../assets/images/payYourWayLogo.svg"
import UniverPaymentMerchant from "../assets/images/UniverPaymentMerchant.svg"
import greenTickCC from "../assets/images/greentickcc.svg"
import errorCCcross from "../assets/images/errorcccross.svg"
import syw_shs_logo from "../assets/images/syw_shs_logo.png"
export const images = {
  mastercard,
  earning,
  cart,
  info,
  lock,
  closewhite,
  user,
  sywpoints,
  creditcardplane,
  grace,
  sywpointsorange,
  close,
  check,
  masterCard,
  visa,
  discover,
  illustration,
  loadingCard,
  closeRed,
  rightGreen,
  cardError,
  NoCreditCardBlue,
  Email,
  Aero,
  Path,
  masterCardStright,
  reload,
  NoCreditCard,
  UnifiLogo,
  PartyIcon,
  Raiselogo,
  UserImg,
  Shop,
  Your,
  Way,
  SYWLogo,
  frameSummary,
  frameSummary1,
  SYWLogo_Black,
  rightArrow,
  bankCardIcon,
  searsHomeServicesLogo,
  payYourWayLogo,
  UniverPaymentMerchant,
  greenTickCC,
  errorCCcross,
  syw_shs_logo
};
