import Payment from "payment";
import { CREDIT_CARD_NUM_MAX_LENGTH, CREDIT_CARD_NUM_MIN_LENGTH } from "../pywConstantComponent/PywConstant";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
    nextValue = `${clearValue.slice(0, 15)} `;
      //  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      //    4,
      //    10
      //  )} ${clearValue.slice(10, 15)}`;
      break;
    // case "dinersclub":
    // nextValue = `${clearValue.slice(0, 14)} `;
      //  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      //    4,
      //    10
      //  )} ${clearValue.slice(10, 14)}`;
      // break;
    default:
    nextValue = `${clearValue.slice(0, 19)} `;
      //  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      //    4,
      //    8
      //  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}
export function validateCreditCardNumber(cardNumber) {
  return cardNumber.length < CREDIT_CARD_NUM_MIN_LENGTH || cardNumber.length > CREDIT_CARD_NUM_MAX_LENGTH
}

export function formatCVC(value , brand) {
  const clearValue = clearNumber(value);
  let maxLength = 0;

  if (brand) {
    maxLength = brand === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function validateCVC(cvv, brand) {
  if(brand === "amex"){
    return cvv?.trim()?.length === 4;
  }
  else {
    return cvv?.trim()?.length === 3;
  }
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function formatNumber(value) {
  return clearNumber(value);
}

export function formatGiftCardNumber(value){
  return value.replace(/^.{16,18}$/,"");
}
